<template>
    <div class="template-page project">
        <basic-page :pagedata="this.pageData" :bannerimg="this.projectImage">
   
            
            <div v-if="projectData.attributes && projectData.attributes.field_project_description" v-html="projectData.attributes.field_project_description.processed"></div>
            <div v-else>No content</div>


            <br>
            <div class="back-button">
                <router-link to="/Projects" class="cta-link">
                    <span>Back to Projects</span>
                </router-link>
            </div>
        </basic-page>
    </div>
</template>

<script>
import BasicPage from '../BasicPage.vue'
import { fetchNodes } from '../../libs/drupalClient';
import { convertMonth } from '../../libs/utils'
export default {
    name: 'project',
    components: {
        BasicPage
    },
    data: () => {
        return {
            pageData: {
                relationships: {}
            },
            introimg: '',
            introimgbannerID: 'bd4230a3-bd52-424f-ba37-aef12581efc5',
            projectData: {}
        }
    },
    computed: {
        projectNID() {
            //console.log('NID', this.$route.params.nid);
            return this.$route.params.nid
        },
        projectImage() {
            return process.env.VUE_APP_ENDPOINT + (this.projectData.field_project_image ? this.projectData.field_project_image.attributes.uri.url : this.introimg)
        },
        deadline() {
            if(this.projectData.attributes.field_is_opportunity_project && this.projectData.attributes.field_opportunity_deadline) {
                var d = new Date(Date.parse(this.projectData.attributes.field_opportunity_deadline))
                return d.getDate() + ' ' + convertMonth(d.getMonth()) + ' ' + d.getFullYear()
            } else
                return ''
        }
    },
    methods: {
        fileSrc(att) {
            return process.env.VUE_APP_ENDPOINT + att.attributes.uri.url
        },
        fetchProject() {
            if(this.projectNID) {
                
                // fetchSingleNode('page', this.introimgbannerID, { 
                //     include: ['field_banner_top'] }
                // ).then(res => {
                //     if(res[0].field_banner_top) this.introimg = res[0].field_banner_top.attributes.uri.url
                // })

                fetchNodes('project', {
                    filters: [{
                        key: 'drupal_internal__nid',
                        value: this.projectNID
                    }],
                    include: ['field_project_image']
                }).then(res => {
                    this.projectData = res[0]

                    var d = new Date(Date.parse(this.projectData.attributes.field_project_data))

                    this.pageData = {
                        title: this.projectData.attributes.title,
                        body: [
                            {
                                summary: d.getDate() + ' ' + convertMonth(d.getMonth()) + ' ' + d.getFullYear()
                            }
                        ],
                        relationships: this.projectData.relationships
                    }

                })
            }   
        },
    },
    mounted() {
        this.fetchProject()
    }
}
</script>

<style lang="scss" scoped>
@import "../../styles/colors.scss";

.template-page.project ::v-deep .header-intro h2 {
    display: none;
}
.deadline h3 {
    padding: .3em .7em;
    margin: 0 0 1em 0;
    background: $mainColor;
    color: $secondaryColor;
    text-transform: unset;
    width: fit-content;

    span { font-weight: 700; }
}

.gallery {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
}

.gallery img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

h4 {
    font-size: 1.25rem;
    color: $mainColor;
    margin: 1em 0 .5em 0;
}

@media (min-width: 1024px) {
    .gallery {
        grid-template-columns: 1fr 1fr;
    }
}

</style>